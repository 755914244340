import React from "react";
import { Footer, Header } from "components";
import Head from "next/head";
import ErrorCard from "components/ErrorCard";

const NotFound = () => {
  return (
    <>
      <Head>
        <title>Não foi possível encontrar esta página.</title>
      </Head>
      <ErrorCard
        title="Não foi possível encontrar esta página."
        description="Não perca tempo e veja nosso catálogo de veículos."
        href="/catalogo"
        action="Ver nossos veículos"
      />
    </>
  );
};

export default NotFound;
